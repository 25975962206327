<template>
    <div>

    </div>
</template>

<script>
    export default {
        name: "MentorUserSide",
        mixins: [],
        components: {
        },
        props: {

        },
        data() {
            return {
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            backEvent() {

            },

        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>